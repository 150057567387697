<template>
  <van-sku
    v-model="show"
    :sku="skuData"
    :goods="skuData.goods"
    :goods-id="skuData.goods.id"
    :hide-stock="skuData.hide_stock"
    :show-add-cart-btn="false"
    :buy-text="skuData.type==1?'加入购物车':'立即购买'"
    @buy-clicked="onBuyClicked"
  />
</template>

<script>
export default {
  components: {},
  props: ["skuData"],
  data() {
    return {
      show: false,
    };
  },
  watch: {},
  computed: {},
  methods: {
    onBuyClicked(e) {
      this.show = false
      e.type = this.skuData.type
      this.$emit('skuBack', e)
    }
  },
  created() {},
  mounted() {},
};
</script>
<style scoped>
.wrapper {
}
</style>