<template>
  <div class="wrapper" v-if="details.pic">
    <van-swipe class="swiper" @change="onChange">
      <van-swipe-item v-for="(item, index) in details.pic" :key="index">
        <img :src="item" alt="">
      </van-swipe-item>
      <template #indicator>
        <div class="custom-indicator">{{ current + 1 }}/{{details.pic.length}}</div>
      </template>
    </van-swipe>
    
    <div class="title-box">
        <div class="flex jc-sb">
          <div class="title">
            {{details.name}}
          </div>
          <div class="share flex ai-c jc-c" @click="shareClick()">
            <img src="/img/share.png" alt="">
          </div>
        </div>
        <div class="flex ai-bl jc-sb" style="margin-top:.3rem">
          <div class="price">{{Number(details.price).toFixed(2)}}</div>
          <div class="sold">已售{{details.sale}}{{details.unit}}</div>
        </div>
        <div class="flex ai-c jc-sb" style="margin-top:.2rem;margin-bottom:.3rem">
          <div class="o-price">原价¥{{Number(details.priceLining).toFixed(2)}}</div>
          <div class="collection flex ai-c" @click="collectClick()" :class="collect&&'isCollection'">
            <van-icon name="like"/>
            收藏
          </div>
        </div>
      </div>
      <!-- <div class="classification flex jc-sb ai-c">
        <div class="flex ai-c jc-c">
            <div class="classification-lb">规格</div>
            <div class="classification-name line">选择规格属性</div>
          </div>
          <van-icon name="arrow" />
      </div> -->

      <div class="nr">
        <div class="nr-title">商品详情</div>
        <div class="fwb" v-html="details.detailHtml"></div>
      </div>
      <div class="kong"></div>
      <div class="btn-box flex" v-if="!$route.query.preview">
        <div class="btn1 flex fd-c ai-c jc-c" @click="$router.push('/home')">
          <img src="/img/icon-home1.png" alt="">
          首页
        </div>
        <div class="btn1 flex fd-c ai-c jc-c" @click="$router.push('/cart')">
          <img src="/img/icon-cart.png" alt="">
          购物车
        </div>
        <div class="btn2" @click="btnClick('1')" style="background:#ff8917">加入购物车</div>
        <div class="btn2" @click="btnClick('2')">立即购买</div>
      </div>
      <template v-if="skuData">
        <v-sku ref="sku" :skuData="skuData" @skuBack="skuBack"  />
      </template>
      
  </div>
</template>

<script>
import { goodsDetails, addCart, collectSave, collectRemove } from '../../../api'
import sku from '@/components/sku'
export default {
  components: {
    'v-sku': sku
  },
  props: {},
  data() {
    return {
      current: 0,
      details: {},
      skuData: {},
      collect: false,
      sku: [],
      shareShow: true
    };
  },
  watch: {},
  computed: {},
  methods: {
    onChange(index) {
      this.current = index;
    },
    btnClick(type){
      this.skuData.type = type
      this.$refs.sku.show = true
    },
    goodsDetails() {
      goodsDetails(this.$route.query.id).then(res=>{
        var goods = res.data.data.goods
        this.collect = res.data.data.collect
        this.sku = res.data.data.sku
        goods.pic = goods.pic?goods.pic.split(','):[]
        
        this.details = goods
        var tree = []
        var specs = res.data.data.specs
        var properties = goods.properties
        var obj = {}
        //判断是否是新的规格返回格式
        if (properties) {
          properties = JSON.parse(properties)
          for (var item of properties) {
            item.k_s = 's'+JSON.parse(JSON.stringify(item.k_id))
            delete item.k_id
          }
          tree = properties
        } else {
          for (var item of specs) {
            if (!obj[item.kid]) {
              obj[item.kid] = {
                k: item.k,
                k_s: 's'+item.kid,
                v: [],
              }
            }
            if (item.sort==0) {
              obj[item.kid].v.push({
                id: item.vid,
                name: item.v,
                // imgUrl: item.pic?item.pic:goods.pic[0],
                // previewImgUrl: item.pic?item.pic:goods.pic[0],
              })
            } else {
              obj[item.kid].v.push({
                id: item.vid,
                name: item.v,
              })
            }
          }
          for (var key in obj) {
            tree.push(obj[key])
          }
        }
        var list = []
        var sku = res.data.data.sku
        for (var i in sku) {
          var item = sku[i]
          tree[0].v[i].imgUrl = tree[0].v[i].imgUrl||item.pic
          tree[0].v[i].previewImgUrl = tree[0].v[i].imgUrl
          item.spData = JSON.parse(item.spData)
          var o = {
            id: item.id,
            stock_num: item.stock,
            price: this.NumberMul(item.price, 100),
          }
          for (var im of item.spData) {
            o['s'+im.k_id] = im.v_id
          }
          list.push(o)
        }
        var goods = goods
        goods.picture = goods.pic[0]
        var skuData = {
          tree: tree,
          list: list,
          price: goods.price.toFixed(2),
          stock_num: goods.stock,
          none_sku: false, // 是否无规格商品
          hide_stock: false, // 是否隐藏剩余库存
          goods: goods
        }
        this.skuData = skuData
        //微信分享设置
        var ua = navigator.userAgent
        // if (ua.match(/MicroMessenger/i) == "micromessenger") {
        //     this.$nextTick(()=>{
        //       // this.$ittShare({title:this.details.name, desc:this.details.subTitle, link:location.origin+location.pathname+'#/login?path=%2Fhome%2Fdetails&id='+this.details.id, imgUrl:this.details.pic[0]})
        //     })
        // }
      })
    },
    //加入购物车或者立即购买回调
    skuBack (e) {
      //加入购物车1
      if (e.type=='1') {
        this.addCart(e.selectedSkuComb.id, e.selectedNum)
      } else {
        console.log(this.sku);
        localStorage.removeItem('orderInfo')
        var pic = ''
        var specifications = []
        for (var item of this.sku) {
          if (item.id == e.selectedSkuComb.id) {
            pic = item.pic?item.pic:this.details.pic[0]
            for (var im of item.spData) {
              specifications.push(im.v)
            } 
            break
          }
        }
        var orderInfo = {
          couponId: null,
          note: null,
          source: 0,
          userAddressId: null,
          name: null,
          phone: null,
          address: null,
          item: [{
            cartId: null,
            quantity: e.selectedNum,
            skuId: e.selectedSkuComb.id,
            price: this.NumberDiv(e.selectedSkuComb.price,100,2),
            name: this.details.name,
            pic: pic,
            specifications: specifications.join('、')
          }]
        }
        localStorage.setItem('orderInfo', JSON.stringify(orderInfo))
        this.$router.push({path: '/home/orderConfirm'})
      }
    },
    //加入购物车
    addCart (id, count) {
      this.$toast.loading({
        duration: 0,
        forbidClick: true,
      })
      addCart({
        id: id,
        count: count
      }).then(res=>{
        this.$toast.success({
            message: '加入购物车成功',
            duration: 1500,
            forbidClick: true,
        })
      }).catch((res)=>{
        this.$toast.clear()
      })
    },
    //点击是否收藏
    collectClick() {
      this.$toast.loading({
        duration: 0,
        forbidClick: true
      })
      if (!this.collect) {
        //收藏
        collectSave({
          id: this.details.id
        }).then(res=>{
          this.$toast.success({
              duration: 1500,
              message: '收藏成功'
          })
          this.collect = true
        }).catch(()=>{
          this.$toast.clear()
        })
      } else {
        collectRemove({
          goodsId: this.details.id
        }).then(res=>{
          this.$toast.success({
                duration: 1500,
                message: '取消收藏成功'
          })
          this.collect = false
        }).catch(()=>{
          this.$toast.clear()
        })
      }
    },
    //分享
    shareClick(){
      var agent = navigator.userAgent.toLowerCase()
      if (/micromessenger/.test(agent)) {
        this.$store.state.shareTs = true
      }
      console.log({title:this.details.name, desc:this.details.subTitle, link:location.origin+location.pathname+'#/login?path=%2Fhome%2Fdetails&id='+this.details.id, imgUrl:this.details.pic[0]});
      this.$ittShare({title:this.details.name, desc:this.details.subTitle, link:location.origin+location.pathname+'#/login?path=%2Fhome%2Fdetails&id='+this.details.id, imgUrl:this.details.pic[0]})
      
     
      // var that = this
      // var ua = navigator.userAgent
      // var preloadJs = function() {
      //   // Promise语法是ES6⽀持的，其他不⽀持的请⾃⾏转成ES5
      //   return new Promise((resolve, rejct)=>{
      //     if (ua.toLowerCase().indexOf("xmsmk") !== -1) {
      //       if (window.UmsApi === undefined) {
      //         document.addEventListener('OnUmsApiReady',
      //         function() {
      //           resolve(true)
      //         },false)
      //       } else {
      //         resolve(true)
      //       }
      //     } else {
      //     // ⾮市⺠卡接⼊
      //       reject('⾮市⺠卡接⼊')
      //     }
      //   })
      // }

      // function share() {
      //   UmsApi.globalization.share({title:that.details.name, desc:that.details.goodsCategoryName, link:location.origin+location.pathname+'#/login?path=%2Fhome%2Fdetails&id='+that.details.id, imgUrl:that.details.pic[0]},
      //   function(data){
      //    // 成功
      //     // UmsApi.notification.toast ("返回数据："+JSON.stringify(data));
      //   },function(data){
      //     // 失败
      //     // UmsApi.notification.toast ("返回数据："+JSON.stringify(data));
      //   });
      // }
      // preloadJs().then(res =>{
      //   // 调⽤市⺠卡相关⽅法
      //   share()
      // })
      
    }
  },
  created() {
    var ua = navigator.userAgent
    if (ua.toLowerCase().indexOf("xmsmk") == -1) {
      
      this.shareShow = false
    }
    this.goodsDetails()
  },
  mounted() {},
  destroyed() {
    var agent = navigator.userAgent.toLowerCase()
    if (/micromessenger/.test(agent)) {
      this.$ittShare({
          title: '厦门市民鹭岛生活·99节',
          desc: '惠民补贴、好物优选，抢先GO!9.9元专区~特惠来袭',
          link: location.origin + location.pathname + '#/home',
          imgUrl: location.origin + location.pathname + 'img/logo_03.png'
      })
    }
    
  }
};
</script>
<style scoped>
.wrapper .swiper{
  position: relative;
  width: 100%;
  height:7.5rem;
}
.wrapper .swiper img{
  display: block;
  width: 100%;
  height:7.5rem;
  object-fit: cover;
}
.custom-indicator {
  position: absolute;
  right: .3rem;
  bottom: .3rem;
  padding: .02rem .05rem;
  font-size: .24rem;
  font-family: PingFang;
  font-weight: 500;
  color: #3C3C3C;
  background: #FFFFFF;
  border-radius: .02rem;
}

.title-box {
  background-color: #fff;
  overflow: hidden;
}
.title-box .title{
  flex: 1;
  /* width: 5.5rem; */
  font-size: .32rem;
  font-family: PingFang;
  font-weight: bold;
  color: #282828;
  margin-left: .28rem;
  margin-right: .28rem;
  margin-top: .3rem;
}
.title-box .share{
  width: 1rem;
  height: .9rem;
  border-left: 1px solid #e7e7e7;
  margin-top: .3rem;
}
.title-box .share img{
  width: .43rem;
}
.title-box .price{
  font-size: .42rem;
  font-family: PingFang;
  font-weight: bold;
  color: #F35E3A;
  margin-left: .3rem;
}
.title-box .price::before{
  content: '¥';
  font-weight: 500;
  color: #F35E3A;
  font-size: .26rem;
}
.title-box .sold{
  font-size: .24rem;
  font-family: PingFang;
  font-weight: 500;
  color: #bbb;
  margin-right: .3rem;
}
.title-box .o-price{
  font-size: .24rem;
  font-family: PingFang;
  font-weight: 500;
  color: #aaa;
  margin-left: .4rem;
  text-decoration: line-through;
}
.title-box .collection{
  font-size: .24rem;
  font-family: PingFang;
  font-weight: 500;
  color: #8B8B8B;
  margin-right: .3rem;
}
.title-box>>>.van-icon-like{
  margin-right: .18rem;
  font-size: .28rem;
  color: rgba(215, 205, 212, 1);
}
.isCollection>>>.van-icon-like{
  color: rgba(249, 111, 48, 1)
}
.nr {
  width: 100%;
  background-color: #fff;
  overflow: hidden;
  margin-top: .2rem;
}
.nr .nr-title{
  font-size: .30rem;
  font-family: PingFang;
  font-weight: bold;
  color: #353535;
  margin-top: .3rem;
  margin-left: .3rem;
}
.fwb{
  width: 100%;
  margin-top: .3rem;
}
.fwb>>>*{
  display: block;
  max-width: 100%;
}
.btn-box{
  position: fixed;
  bottom: 0;
  left: 0;
  width: 7.50rem;
  height: 1rem;
  background: #FFFFFF;
  border-top: 1px solid rgba(178, 178, 178, 0.6);
  padding-bottom: constant(safe-area-inset-bottom); /*兼容 IOS<11.2*/
  padding-bottom: env(safe-area-inset-bottom); /*兼容 IOS>11.2*/
}
.btn-box .btn1{
  width: 1.2rem;
  height: 100%;
  font-size: .22rem;
  font-family: PingFang;
  font-weight: 500;
  color: #61626A;
}
.btn-box .btn1 img{
  height: .36rem;
  margin-top: .1rem;
}
.btn-box .btn1:nth-child(1){
  border-right: 1px solid rgba(178, 178, 178, 0.6);
}
.btn-box .btn2{
  flex: 1;
  height: 100%;
  background: #F35E3A;
  font-size: .36rem;
  font-family: PingFang;
  font-weight: 500;
  color: #FFFFFF;
  font-size: .36rem;
  font-family: PingFang;
  font-weight: 500;
  color: #FFFFFF;
  text-align: center;
  line-height: 1rem;
}
.kong{
  width: 100%;
  height: 1.5rem;
}
.classification{
  width: 100%;
  height: 1rem;
  background-color: #fff;
  margin-top: .2rem;
}
.classification .classification-lb{
  font-size: .24rem;
  font-family: PingFang;
  font-weight: 500;
  color: #998D9A;
  margin-left: .3rem;
}
.classification .classification-name{
  width: 5rem;
  font-size: .24rem;
  font-family: PingFang;
  font-weight: 500;
  color: #353535;
  margin-left: .4rem;
}
.classification>>>.van-icon-arrow{
  color: rgba(75, 75, 75, 1);
  margin-right: .2rem;
}
</style>